var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TechnologySelector"},[(_vm.isEditing)?_c('h3',[_vm._v(" "+_vm._s(_vm.usedFor)),_c('v-icon',{staticClass:"edit",attrs:{"small":"","color":"light-blue","right":""},on:{"click":_vm.toggleEditing}},[_vm._v("mdi-pencil-circle-outline")])],1):_c('h3',[_vm._v(" "+_vm._s(_vm.usedFor)),_c('v-icon',{staticClass:"edit",attrs:{"small":"","color":"accentRed","right":""},on:{"click":_vm.toggleEditing}},[_vm._v("mdi-pencil-lock-outline")])],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.dontSubmit($event)}}},[_c('ul',{staticClass:"newTech"},_vm._l((_vm.bank),function(item,i){return _c('li',{key:i},[_c('v-chip',{staticClass:"chip",attrs:{"outlined":_vm.isEditing,"label":"","color":_vm.isEditing ? 'light-blue' : 'secondary',"close":""},on:{"click:close":function($event){return _vm.removeItem(item)}}},[_vm._v(_vm._s(item))])],1)}),0),(_vm.isEditing)?_c('v-text-field',{attrs:{"disabled":!_vm.isEditing,"clearable":"","placeholder":this.placeholder,"hint":_vm.currentSelected
          ? 'Press Enter to add ' + _vm.currentSelected
          : _vm.partialMatchString.length > 0
          ? 'Press Enter to add ' + _vm.partialMatchString
          : 'Type for autocomplete',"persistent-hint":""},on:{"keyup":_vm.keyWasPressed},model:{value:(_vm.adding),callback:function ($$v) {_vm.adding=$$v},expression:"adding"}}):_c('v-text-field',{attrs:{"disabled":!_vm.isEditing,"placeholder":this.placeholder,"hint":_vm.currentSelected
          ? 'Press Enter to add ' + _vm.currentSelected
          : _vm.partialMatchString.length > 0
          ? 'Press enter to add:' + _vm.partialMatchString
          : '',"persistent-hint":""},on:{"keyup":_vm.keyWasPressed},model:{value:(_vm.saved),callback:function ($$v) {_vm.saved=$$v},expression:"saved"}})],1),(_vm.technologies.length > 0)?_c('div',{staticClass:"matchWrap"},_vm._l((_vm.partialMatches),function(matches,i){return _c('ul',{key:i},[_c('li',[_c('v-chip',{staticClass:"chip",attrs:{"outlined":matches !== _vm.currentSelected,"label":"","color":"orange"},on:{"click":function($event){return _vm.addThis(matches)}}},[_vm._v(_vm._s(matches))])],1)])}),0):_vm._e(),_c('div',{staticClass:"confirm-btn-wrap",attrs:{"align":"right"}},[_c('v-btn',{staticClass:"confirm-btn",attrs:{"small":"","disabled":this.bank == 0 || !this.isEditing,"color":"secondary"},on:{"click":_vm.submit}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-check")]),_vm._v("Confirm "+_vm._s(_vm.usedFor))],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }