<template>
  <div class="about">
    <InfoBanner />
  </div>
</template>

<script>
import InfoBanner from "@/components/About/InfoBanner.vue";
export default {
  name: "About",
  components: {
    InfoBanner,
  },
};
</script>

<style scoped>
</style>